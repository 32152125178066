<template>
    <div style="height: inherit">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Index',
  components: {
  },
  data() {
    return {
      // componentId: 'ClientsList',
      // loading: false,
      // statusTab: 1,
      // tabs: [
      //   { status: 1, tab: 'ClientsList', name: 'Клиенты' },
      //   { status: 2, tab: 'closed', name: 'Платежи' },
      //   { status: 3, tab: 'overdue', name: 'Карты' },
      //   { status: 5, tab: 'not_confirmed', name: 'Ошибка при регистрации' },
      //   { status: 6, tab: 'all', name: 'Статистика' },
      //   { status: 4, tab: 'rejected', name: 'Филиалы' },
      // ],
    }
  },
  computed: {
    // isHasPermission() {
    //   return (this.user.role.name == 'admin')
    // },
    // title() {
    //   const activeTab = this.tabs.find(el => el.tab == this.filterModel.tab)
    //   if (activeTab) {
    //     return activeTab.name
    //   }
    //   return 'Список договора'
    // },
  },

  mounted() {
  },
  methods: {
    ...mapActions({
      // getItemsAction: 'contract/index',
    }),
  },
}
</script>

<style scoped></style>
